// Assets
import auraPic from '../images/projects/Aura/pic_desktop_one.png';
import repackPic from '../images/projects/RePack/pic_desktop_one.png';
import ctgPic from '../images/projects/CareerToGo/pic_desktop_one.png';
import socialPic from '../images/projects/Social/pic_desktop_one.png'
import stokkPic from '../images/projects/Stokk/pic_desktop_one.png'
import klappPic from '../images/projects/Klapp/pic_desktop_one.png'

export const projectsCard = [
  {
    header_main: 'Klappid',
    desc:
      'Website new payment system for buses in Iceland',
    link_text: 'Check Project',
    link: '/projectKlapp',
    img: `${klappPic}`
  },
  {
    header_main: 'Stokkur Software',
    desc:
      'Website of App oriented SoftwareHouse based in Iceland.',
    link_text: 'Check Project',
    link: '/projectStokk',
    img: `${stokkPic}`
  },
  {
    header_main: 'AuraTattoo',
    desc:
      'Tattoo Shop web page, which can help to find and book the best tattoo artist from Reykjavik.',
    link_text: 'Check Project',
    link: '/projectAura',
    img: `${auraPic}`
  },
  {
    header_main: 'The Social Made',
    desc:
      'Website of Social Media marketing agency based in USA/Iceland',
    link_text: 'Check Project',
    link: '/projectSocial',
    img: `${socialPic}`
  },
  {
    header_main: 'RePack',
    desc:
      'MVP built for Norwegian company focused on the reusable source of energy.',
    link_text: 'Check Project',
    link: '/projectRepack',
    img: `${repackPic}`
  },
  {
    header_main: 'CareerToGo',
    desc:
      'MVP project built for Danish startup. Job platform for youth with job events, build with VueJS and NuxtJS.',
    link_text: 'Check Project',
    link: '/projectCTG',
    img: `${ctgPic}`
  }
];
