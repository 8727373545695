// img_project_one
import p1Aura from '../images/projects/Aura/project_one.png';
import p1Social from '../images/projects/Social/project_one.png';
import p1Klapp from '../images/projects/Klapp/project_one.png';
import p1Stokk from '../images/projects/Stokk/project_one.png';
import p1Ctg from '../images/projects/CareerToGo/project_one.png';
import p1Repack from '../images/projects/RePack/project_one.png';
// img_project_overall
import o1Aura from '../images/projects/Aura/project_three.png';
import o1Social from '../images/projects/Social/project_three.png';
import o1Klapp from '../images/projects/Klapp/project_three.png';
import o1Stokk from '../images/projects/Stokk/project_three.png';
import o1Ctg from '../images/projects/CareerToGo/project_three.png';
import o1Repack from '../images/projects/RePack/project_three.png';
// img_project_two
import p2Aura from '../images/projects/Aura/project_two.png';
import p2Social from '../images/projects/Social/project_two.png';
import p2Klapp from '../images/projects/Klapp/project_two.png';
import p2Stokk from '../images/projects/Stokk/project_two.png';
import p2Ctg from '../images/projects/CareerToGo/project_two.png';
import p2Repack from '../images/projects/RePack/project_two.png';

export const projectsPage = [
  {
    name: '/projectSocial',
    header_main: 'The Social Made',
    paragraph: 'This project was created for Social Media Agency - The Social Made - based in US/Iceland ',
    table: {
      type: 'Commercial',
      stack: [
        'Gatsby',
        'GraphQL',
        'Styled-components',
        'MaterialUI',
        'Netlify'
      ],
      code: {
        text: 'Repository',
        link: 'https://github.com/Ryjekk/TheSocialMade'
      },
      live: {
        text: 'Live view',
        link: 'https://kierstynmarketing.netlify.app/'
      }
    },
    img_project_one: `${p1Social}`,
    header_goal: 'Project Goal and Purpose',
    paragraph_goal_one: 'Gof of this project was to create steady platform for communicate with customers, like also to promote Agency.',
    paragraph_goal_two: 'The Social Made is on a market since 2018 and through the years had couple successful clients: like Michelin nominate restaurant - Nostra',
    header_explanation: 'Web Stack and Reasoning',
    paragraph_explanation_one: 'As a basic web stack we decided to use ReactJS which give us advantage of fast rendering and ease of typing.',
    paragraph_explanation_two: 'Also because of really interactive interface, we could took advantage of utilizing third party libraries which react can provide to us.',
    stack_badges: [
      'ReactLogo',
      'Graphql',
      'StyledComponents',
      'MaterialUi',
      'Netlify'
    ],
    img_project_overall: `${o1Social}`,
    header_problems: 'Thought Process and Encountered Problems',
    paragraph_problems: '',
    img_project_two: `${p2Social}`,
    header_lesson: 'Future Improvements',
    paragraph_lesson: 'As a further improvement we can see implementing small cosmetic features like light mode, also plugging content delivery network for ease usage by our customer '
  },
  {
    name: '/projectKlapp',
    header_main: 'Klappid.is',
    paragraph: 'KLAPP is a new account based ticketing system that provides access to Strætó buses in the capital area. This type of system is known in public transport around the world, where a card or mobile phone is put up to a scanner when fares are paid in the buses.',
    table: {
      type: 'Commercial',
      stack: [
        'NextJS',
        'CSS Module',
        'Google Cloud Platform'
      ],
      code: {
        text: 'Ask for access',
        link: 'mailto:ryjewski@gmail.com'
      },
      live: {
        text: 'Live view',
        link: 'https://www.klappid.is/'
      }
    },
    img_project_one: `${p1Klapp}`,
    header_goal: 'Project Goal and Purpose',
    paragraph_goal_one: 'Purpose of creating Klapp.is was to inform people about new product on the market.',
    paragraph_goal_two: 'Klapp.is allows user to connect directly with bus provider in Iceland, look up for Frequently asked questions, search for vendors amd more.',
    header_explanation: 'Web Stack and Reasoning',
    paragraph_explanation_one: 'We used NextJs in this project to equalize stack used within Stokkur SoftwareHouse. Also NextJS provides SSR content which is relatively faster than regular client side rendered content. ',
    paragraph_explanation_two: 'Whole project was created as a part of work for Stokkur.',
    stack_badges: [
      'ReactLogo',
      'NextDotJs',
      'StyledComponents',
      'MaterialUi'
    ],
    img_project_overall: `${o1Klapp}`,
    header_problems: 'Thought Process and Encountered Problems',
    paragraph_problems: 'This project was relatively simple, and easy going one. Process which we encountered were mainly caused on Manager CMS system.',
    img_project_two: `${p2Klapp}`,
    header_lesson: 'Future Improvements',
    paragraph_lesson: 'As a further improvement we see possibility of plugin CMS therefore our customer will be able to edit content by themself.'
  },
  {
    name: '/projectStokk',
    header_main: 'Stokkur Software',
    paragraph: 'Stokkur Software is an App focused Software house based in downtown of Reykjavik in Iceland. This studio released lots of useful - day to day Apps like: Dominos, Straeto, Klapp, Vedur and more',
    table: {
      type: 'Commercial',
      stack: [
        'NextJS',
        'Styled Components',
        'Localize',
        'Google Cloud Platform'
      ],
      code: {
        text: 'Ask for access',
        link: 'mailto:ryjewski@gmail.com'
      },
      live: {
        text: 'Live view',
        link: 'https://stokkur.is/'
      }
    },
    img_project_one: `${p1Stokk}`,
    header_goal: 'Project Goal and Purpose',
    paragraph_goal_one: 'The purpose of this project was to create a new refreshed website for Stokkur Software. Website supposed to be up to today standards, with all useful features.',
    paragraph_goal_two: 'Whole project was created as a part of work for Stokkur.',
    header_explanation: 'Web Stack and Reasoning',
    paragraph_explanation_one: 'To equalize stack used within the house we decided to use Next JS which with new version - 12.0 - can provide bunch of useful features, therefore we could cut all back-end code and squeeze it within Next project.',
    paragraph_explanation_two: 'As a styling engine we used Styled components - which perfectly firs to this project, since we created our layouts modal and modular.',
    stack_badges: [
      'ReactLogo',
      'NextDotJs',
      'StyledComponents',
    ],
    img_project_overall: `${o1Stokk}`,
    header_problems: 'Thought Process and Encountered Problems',
    paragraph_problems: 'The most challenging part of this project was to face new design, which was created that way to catch new users eye. Also since it contains a lot of offset items, the harder part was to place them in correct and clean way.',
    img_project_two: `${p2Stokk}`,
    header_lesson: 'Future Improvements',
    paragraph_lesson: 'As a further improvement we can see plugging CMS for managing content on the website.'
  },
  {
    name: '/projectAura',
    header_main: 'AuraTattoo',
    paragraph:
      'AuraTattoo is a React Application, built for a tattoo studio based in Reykjavik Iceland. I built this project from scratch using React. This webpage should allows potential clients to view tattoo shop artist, their work like also allow them to make a quick booking for an appointment',
    table: {
      type: 'Commercial',
      stack: [
        'React',
        'Mongodb',
        'GraphQL',
        'Strapi',
        'Styled-components',
        'MaterialUI',
        'Netlify'
      ],
      code: {
        text: 'Repository',
        link: 'https://github.com/Ryjekk/Ethereal'
      },
      live: {
        text: 'Live view',
        link: 'https://www.aurareykjavik.com/'
      }
    },
    img_project_one: `${p1Aura}`,
    header_goal: 'Project Goal and Purpose',
    paragraph_goal_one:
      'This project had three main goals: Allows user to check artist and their work, display all artist coming for the guest spot, and also allow user to make simple booking application.',
    paragraph_goal_two:
      'I think all of this goals was achieved on final stage of product. Since this project is still under active development The product owner is thinking about expanding website adding for example a shop functionality.',
    header_explanation: 'Web Stack and Reasoning',
    paragraph_explanation_one:
      "For this project I used React, but many different Javascript libraries could've been used, and even vanilla Javascript could have been easily implemented. I chose to use React because of its ease of use and the ability to smoothly manipulate states without having to re-render the pages.",
    paragraph_explanation_two:
      'In this project I also found out way to use EmailJS to provide smooth direct message system between Client and Aura Tattoo studio.',
    stack_badges: [
      'ReactLogo',
      'Mongodb',
      'Graphql',
      'Strapi',
      'StyledComponents',
      'MaterialUi',
      'Netlify'
    ],
    img_project_overall: `${o1Aura}`,
    header_problems: 'Thought Process and Encountered Problems',
    paragraph_problems:
      'This project was created after learning about React, therefore during the process of coding I encountered lots of small challenges. I tried to build and structure code as clean as I could.',
    img_project_two: `${p2Aura}`,
    header_lesson: 'Future Improvements',
    paragraph_lesson:
      'Since this project was mainly build using class components as a future improvements I can see migrating to React Hooks. Also This project is still under heavy development - therefore we can expect new features like Shop in the close feature'
  },
  {
    name: '/projectCTG',
    header_main: 'CareerToGo',
    paragraph:
      'CareerToGo is a NuxtJS/VueJS application which allows young people to find a job, search for job events and look through interesting companies. I took a part in this product as a Intern and later Front-end Developer',
    table: {
      type: 'Commercial',
      stack: ['VueJS', 'NuxtJS', 'TailwindCSS', 'MySQL', 'AWS'],
      code: {
        text: 'Ask for access',
        link: 'mailto:ryjewski@gmail.com'
      },
      live: {
        text: 'FB profile',
        link: 'https://www.facebook.com/CareerToGo/'
      }
    },
    img_project_one: `${p1Ctg}`,
    header_goal: 'Project Goal and Purpose',
    paragraph_goal_one:
      'Finding a job should be simple and engaging. That is why we developed CareerToGo, where students and graduates meet their future employers in informal coffee meetings.',
    paragraph_goal_two:
      'This project is still under active development process.',
    header_explanation: 'Web Stack and Reasoning',
    paragraph_explanation_one:
      "In this project we decided to use NuxtJS/VueJS framework since it's not as demanding as React and entry level for new developer was less lower. We decided to use NuxtJS since it provide for us better optimisation towards SEO, also sever-side rendering gave us better performance results. TailwindCSS was chosen mainly for purpose of being able to style our product in same way, no matter who will work over code.",
    paragraph_explanation_two:
      "This product is using PHP and its framework Laravel 6. As a way to store data we decided to use MySQL because in our case data which we collect in expected - therefore it's fits into Schema approach provided by SQL languages. Whole product is hosted on AWS S3 bucket",
    stack_badges: ['Vuejs', 'NuxtDotJs', 'Amazonaws', 'Mysql', 'Tailwindcss'],
    img_project_overall: `${o1Ctg}`,
    header_problems: 'Thought Process and Encountered Problems',
    paragraph_problems:
      'During my working days usually we were trying to solve problems on fly. Since that was my first real life project I had opportunity to learn from the best Designers and Developers.',
    img_project_two: `${p2Ctg}`,
    header_lesson: 'Lesson Learned',
    paragraph_lesson:
      'As a first real product work experience it was amazing to be able to see and relay on knowledge of my older colleges. Each day we had morning meeting where we disposed daily tasks and we talked about long time runs. Time spent in CareerToGo ground me even more in that that being a Software Developer is something what I really want to do.'
  },
  {
    name: '/projectRepack',
    header_main: 'RePack Battery Cloud',
    paragraph:
      'RePack Battery Cloud is a React application which control reusable battery packs, monitoring live data outsourced straight from batteries. This MVP is granted with 350 kNOK by Regionale Forskningsfond Oslo.',
    table: {
      type: 'Commercial',
      stack: [
        'ReactJS',
        'NodeJS',
        'Express',
        'PostgreSQL',
        'MaterialUI',
        'Heroku',
        'Netlify'
      ],
      code: {
        text: 'Ask for access',
        link: 'mailto:ryjewski@gmail.com'
      },
      live: {
        text: 'Live view',
        link: 'https://repack-cloud.netlify.app/'
      }
    },
    img_project_one: `${p1Repack}`,
    header_goal: 'Project Goal and Purpose',
    paragraph_goal_one:
      'RePack develops technologies to extract maximum value from second life EV batteries by repurposing them in a streamlined, safe and cost-effective way. Over two weeks, we supported the startup with mob in order to develop a MVP of their Battery Cloud solution. ',
    paragraph_goal_two:
      'During my work I was responsible for the client’s overview panel based on raw data from the battery modules using React in front end and Chart.js to display the graph. On the backend Nodejs was used with Postgresql as the database management system. The panel includes real-time updates from the battery sensors, and displays the voltage, current and temperature for each individual system.',
    header_explanation: 'Web Stack and Reasoning',
    paragraph_explanation_one:
      'Since we had to deal with structured data we decided to use PostgreSQL. This database is strictly recommended for project like ours. ',
    paragraph_explanation_two:
      'As a front end solution we decided to use React and Chart.js to display the graph.',
    stack_badges: [
      'ReactLogo',
      'Nodejs',
      'Express',
      'Postgresql',
      'MaterialUi',
      'Netlify',
      'Heroku'
    ],
    img_project_overall: `${o1Repack}`,
    header_problems: 'Thought Process and Encountered Problems',
    paragraph_problems:
      'Our biggest problem on this assigment was to decide how our incoming data should look like in database, like also what is the best way to model and store it. Gladly during the process of work we found the best solution for collecting data.',
    img_project_two: `${p2Repack}`,
    header_lesson: 'Future Improvements',
    paragraph_lesson:
      'Since final MVP was based on dummy data as a future improvements we see good opportunity to plug our database to app itself. Since App have an MVP status it will be further developed.'
  }
];
