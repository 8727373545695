import saltWorkPic from '../images/workspace/Salt/pic_desktop_one.png';

export const largeCard = [
  {
    header_main: 'Studying at Salt',
    desc:
      'Read more about my amazing experience while studying at </Salt>! School for developers placed in Stockholm.',
    link: '/workSalt',
    link_text: `Read More ›`,
    img: `${saltWorkPic}`
  }
];
